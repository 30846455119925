/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'zone.js';
import { environment } from './environments/environment';

if (environment.production) {
  // Production
} else {
  // Development
  interface WindowsError extends ErrorConstructor {
    stackTraceLimit: number;
  }
  (<WindowsError>Error).stackTraceLimit = Infinity;
}

// This was so the booking page worked in IE
// TODO: move the booking page outside Angular, to a server rendered page?
(function (arr) {
  arr.forEach(function (item) {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
